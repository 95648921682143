import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Section from '../components/section';

const ImprintPage = () => (
  <Layout fluidContainer backToHome>
    <SEO />

    <Section title="Impressum" headerStyle={{ marginTop: '200px' }}>
      <br />
      <p style={{ fontSize: '0.8rem' }}>Angaben gemäß §5 TMG:</p>
      <p><strong>Helmut & Anke Eichinger</strong></p>
      <p>
        Ammerländer Straße 29<br />
        27809 Lemwerder<br />
      </p>
      <p><strong>Disclaimer:</strong></p>
      <p>
        Our website contains links to external webpages. Despite our careful
        content control, we assume no liability for the content of external webpages. We have no influence on
        the content of external webpages and the operators of the linked webpages are solely responsible for
        their content.
      </p>
    </Section>
  </Layout>
)

export default ImprintPage
