/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import './section.css';


const Section = ({ children, title, fluidContainer = false, style = {}, className = {}, contentContainerStyle = {}, headerStyle = {} }) => {
  let containerStyle = {
    margin: `48px auto`,
    maxWidth: 960,
    minHeight: '50vh',
    ...contentContainerStyle,
  };

  if (fluidContainer) {
    containerStyle = {
      margin: `0 auto`,
      padding: `0`,
      height: '90vh',
      ...contentContainerStyle,
    };
  }

  const headlineStyle = {
    borderTop: '8px solid #62b7f0',
    color: '#1F2933',
    fontSize: '20px',
    margin: '0 auto',
    marginBottom: '48px',
    paddingTop: '16px',
    textAlign: 'center',
    width: '160px',
    textTransform: 'uppercase',
    ...headerStyle,
  };

  return (
    <div className={className} style={style}>
      <div className="section__container" style={containerStyle}>
        {title && (<h2 style={headlineStyle}>{title}</h2>)}
        {children}
      </div>
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
